<script setup lang="ts">
import { RouterView, useRouter, useRoute } from 'vue-router'
import BaseToast from '@/components/shared/BaseToast.vue'
import { useIdle } from '@vueuse/core'
import { useAuthStore } from '@/stores/authStore'
import { watch } from 'vue'

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const { idle } = useIdle(30 * 60 * 1000) // 30 minutes

watch(idle, async (idleValue) => {
    if (idleValue && route.path !== '/') {
        await authStore.logout()

        router.push('/')
    }
})
</script>

<template>
    <RouterView v-slot="{ Component }">
        <BaseToast />
        <Transition name="fade" mode="out-in">
            <component :is="Component" />
        </Transition>
    </RouterView>
</template>

<style lang="postcss"></style>
