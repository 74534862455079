import { useAuthStore } from '@/stores/authStore'
import NProgress from 'nprogress'
import { createRouter, createWebHistory } from 'vue-router'

NProgress.configure({ showSpinner: false })

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'LoginPage',
            component: () => import('../views/login/LoginPage.vue'),
            meta: {
                authPage: true,
            },
        },

        {
            path: '/dashboard',
            name: 'DashboardPage',
            component: () => import('@/layouts/DashboardLayouts.vue'),
            redirect: '/dashboard/form',
            meta: {
                requireAuth: true,
            },
            children: [
                {
                    path: '/dashboard/my-spk',
                    name: 'SpkIndexPage',
                    component: () => import('../views/spk/SpkIndexPage.vue'),
                },
                {
                    path: '/dashboard/users',
                    name: 'UsersIndexPage',
                    component: () => import('../views/users/UsersIndexPage.vue'),
                },
                {
                    path: '/dashboard/roles',
                    name: 'RolesIndexPage',
                    component: () => import('../views/roles/RolesIndexPage.vue'),
                },
                {
                    path: '/dashboard/document-number-templates',
                    name: 'NumberingIndexPage',
                    component: () => import('../views/numbering/NumberingIndexPage.vue'),
                },
                {
                    path: '/dashboard/approval-templates',
                    name: 'ApprovalIndexPage',
                    component: () => import('../views/approvals/IndexPage.vue'),
                },

                {
                    path: '/dashboard/approval/show/:id',
                    name: 'ApprovalShowPage',
                    component: () => import('../views/approvals/ShowPage.vue'),
                },

                {
                    path: '/dashboard/approval/edit/:id',
                    name: 'ApprovalsEditPage',
                    component: () => import('../views/approvals/EditPage.vue'),
                },

                {
                    path: '/dashboard/form',
                    name: 'FormIndexPage',
                    component: () => import('../views/forms/FormListPage.vue'),
                },

                {
                    path: '/dashboard/form/:formName',
                    name: 'FormReadPage',
                    component: () => import('../views/forms/FormIndexPage.vue'),
                },

                {
                    path: '/dashboard/form-approval',
                    name: 'FormApproveableIndexPage',
                    component: () => import('../views/form-approveable/IndexPage.vue'),
                },

                {
                    path: '/dashboard/currencies',
                    name: 'CurrenciesIndexPage',
                    component: () => import('../views/currencies/IndexPage.vue'),
                },
            ],
        },

        {
            path: '/user/create',
            name: 'UsersCreatePage',
            component: () => import('../views/users/UsersCreatePage.vue'),
            meta: {
                requireAuth: true,
            },
        },
        {
            path: '/user/edit/:id',
            name: 'UsersEditPage',
            component: () => import('../views/users/UsersEditPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/role/create',
            name: 'RolesCreatePage',
            component: () => import('../views/roles/RolesCreatePage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/role/edit/:id',
            name: 'RolesEditPage',
            component: () => import('../views/roles/RolesEditPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/numbering/create',
            name: 'NumberingCreatePage',
            component: () => import('../views/numbering/NumberingCreatePage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/numbering/edit/:id',
            name: 'NumberingEditPage',
            component: () => import('../views/numbering/NumberingEditPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/approval/create/:id',
            name: 'ApprovalsCreatePage',
            component: () => import('../views/approvals/CreatePage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/currencies/create',
            name: 'CurrenciesCreatePage',
            component: () => import('../views/currencies/CreatePage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/currencies/edit/:id',
            name: 'CurrenciesEditPage',
            component: () => import('../views/currencies/EditPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/:formName/:id/edit',
            name: 'FormEditPage',
            component: () => import('../views/forms/FormEditPage.vue'),
        },

        {
            path: '/:formName/:id/view',
            name: 'FormViewPage',
            component: () => import('../views/forms/FormViewPage.vue'),
        },

        {
            path: '/profile',
            name: 'ProfilePage',
            component: () => import('../views/profile/IndexPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/profile/edit',
            name: 'ProfilePageEdit',
            component: () => import('../views/profile/EditPage.vue'),
            meta: {
                requireAuth: true,
            },
        },

        {
            path: '/profile/change-password',
            name: 'ChangePasswordPage',
            component: () => import('../views/profile/ChangePasswordPage.vue'),
            meta: {
                requireAuth: true,
            },
        },
    ],
})

router.beforeEach(async (to, from) => {
    let userStore = useAuthStore()

    NProgress.start()

    // If userdata is null ( mean user is not logged in )
    if (userStore.user === null) {
        await userStore.getUserAuth()
    }

    // Check If already login
    if (to.meta.authPage && userStore.authStatus) {
        if (userStore.user !== null) {
            router.push('/dashboard')
        } else {
            router.push('/')
        }
    }

    // Check if not Login
    if (to.meta.requireAuth && !userStore.authStatus) {
        if (userStore.user === null) {
            router.push('/')
        } else {
            router.push('/dashboard')
        }
    }

    // Check Status Inactive
    if (userStore.user !== null && userStore.user.user.status === 0) {
        await userStore.logout()
        router.push('/')
    }

    if (userStore.user !== null && userStore.user.user.roles[0].status === 0) {
        await userStore.logout()
        router.push('/')
    }

    // // Check User Permission
    // if (userStore.user !== null && to.meta.permissions && userStore.user.permissions.includes(to.meta.permissions) == false) {
    //     if (userStore.user.name == 'Guest') {
    //         router.push('/new-report')
    //     } else {
    //         router.push('/dashboard/reports')
    //     }
    // }
})

router.afterEach(() => {
    NProgress.done()
})

router.onError((error, to) => {
    const errors = ['dynamically imported module', 'Unable to preload CSS']

    if (errors.some((e) => error.message.includes(e))) {
        window.location.href = to.fullPath
    }
})

export default router
