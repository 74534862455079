import axios from 'axios'
import { toast } from 'vue-sonner'

const http = axios.create({
    baseURL: import.meta.env.VITE_BASE_URL as string,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
})

http.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        toast.error(error.response?.data.message)
        return Promise.reject(error)
    },
)

export default http
