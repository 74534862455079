import { defineStore } from 'pinia'
import http from '@/assets/plugin/axios'
import { ref } from 'vue'
import router from '@/router'
import axios from 'axios'

import type { AuthModel } from '@/model/auth.model'

export const useAuthStore = defineStore('user', () => {
    const user = ref<AuthModel | null>(null)
    const authStatus = ref(false)

    const setUser = (newUser: AuthModel | null) => {
        user.value = newUser
    }

    const setAuthStatus = (newAuthStatus: boolean) => {
        authStatus.value = newAuthStatus
    }

    // Login function
    const login = async (email: string, password: string) => {
        return await http.get('sanctum/csrf-cookie').then(async () => {
            return await http
                .post('/login', {
                    email: email,
                    password: password,
                })
                .then((res) => {
                    setAuthStatus(true)
                    setUser(res.data)

                    return res
                })
                .catch((err) => {
                    throw err
                })
        })
    }

    // get user auth
    const getUserAuth = async () => {
        return await http
            .get('/api/me')
            .then((res) => {
                setAuthStatus(true)
                setUser(res.data)
            })
            .catch((err) => {
                if (axios.isAxiosError(err)) {
                    if (err.response?.status === 422) {
                        setAuthStatus(false)
                        setUser(null)
                        router.push('/')
                    }
                }
            })
    }

    // Logout function
    const logout = async () => {
        return await http.post('/logout').then((res) => {
            setAuthStatus(false)
            setUser(null)
        })
    }

    return {
        user,
        authStatus,
        setUser,
        setAuthStatus,
        getUserAuth,
        login,
        logout,
    }
})
